<template>
  <div>
    <div class="xs_hide footer">
      <div class="footer-bg">
        <div class="container">
          <div class="end">
            <div class="end-item">
              <img src="@/assets/address.png" alt="">
              <div>Tower A at Eightyeight Kasablanka 16th
                Floor, Jl. Raya Casablanka Kav 88.
                Menteng Dalam, Tebet, Jakarta Selatan
                12870</div>
            </div>

            <div class="end-item">
              <img src="@/assets/phone.png" alt="">
              <div>+6281 2111 30223 </div>
            </div>
            <div class="end-item">
              <img src="@/assets/email.png" alt="">
              <div>server@coinbac.id</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="xs_block">
      <div class="end">
        <div class="end-item">
          <img src="@/assets/icons/address.png" alt="">
          <div>Tower A at Eightyeight Kasablanka 16th
            Floor, Jl. Raya Casablanka Kav 88.
            Menteng Dalam, Tebet, Jakarta Selatan
            12870</div>
        </div>

        <div class="end-item">
          <img src="@/assets/icons/phone.png" alt="">
          <div>+6281 2111 30223 </div>
        </div>
        <div class="end-item">
          <img src="@/assets/icons/email.png" alt="">
          <div>server@coinbac.id</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.xs_block {
  color: #fff;
  font-size: 10px;
  background: #5F6CFF;

  .end {
    padding: 20px;
  }

  .end-item {
    width: 60%;
    display: flex;
    align-items: center;
    padding: 10px 0;

    img {
      margin-right: 20px;
    }
  }
}

.footer {
  position: relative;
  background: #9DF2F2;
  .footer-bg {
    background: url('../../../assets/bg-footer.png') no-repeat;
    background-size: 100% 101%;
  }

  .footer-title {
    color: #fff;
    font-size: 30px;
  }

  .container {
    display: flex;
    justify-content: flex-end;
    padding: 100px 0;

    .end {
      display: flex;
      color: #fff;
      font-size: 16px;

      .end-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        text-align: center;
      }

      img {
        width: 40px;
        margin: 20px;
      }
    }
  }
}
</style>